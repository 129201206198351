import React from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button, LegacyForms } from '@grafana/ui';
import { TagDefinition } from 'types';

export const TagDefinitionsSetter: React.FC<StandardEditorProps<TagDefinition[]>> = ({ item, value, onChange, context }) => {

  const addTagDefinition = () => {
    let newDefinitionArr = value;
    if (newDefinitionArr == undefined){
      newDefinitionArr = [];
    }

    const newDefinition: TagDefinition = {
      name: '',
      displayName: '',
      defaultValue: '',
      hidden: false,
      readonly: false,
    };

    newDefinitionArr.push(newDefinition);
    onChange(newDefinitionArr);
  };
  
  const removeTagDefinition = (index: number) => {
    const newDefinitionArr = value;
    newDefinitionArr.splice(index, 1);
    onChange(newDefinitionArr);
  };
  const onTagDefinitionChanged = (definition: TagDefinition, definitionIndex: number) => {
    const newDefinitionArr = value;
    newDefinitionArr[definitionIndex] = definition;
    onChange(newDefinitionArr);
  };

  return (
    <div>
      {value?.map((item, index) => (
        <div className="ti-gf-form">
          <span className="gf-form-label">
            <i className="fa fa-remove pointer" onClick={() => removeTagDefinition(index)}></i>
          </span>
        &nbsp;
          <LegacyForms.FormField
            label="Name"
            type="text"
            labelWidth={500}
            inputWidth={300}
            value={item.name}
            onChange={(e: any) => {
              item.name = e.target.value;
              onTagDefinitionChanged(item, index);
            }}
          />
        &nbsp;
          <LegacyForms.FormField
            label="Display name"
            type="text"
            labelWidth={500}
            inputWidth={300}
            value={item.displayName}
            onChange={(e: any) => {
              item.displayName = e.target.value;
              onTagDefinitionChanged(item, index);
            }}
          />{' '}
        &nbsp;
          <LegacyForms.FormField
            label="Value"
            type="text"
            labelWidth={500}
            inputWidth={300}
            value={item.defaultValue}
            onChange={(e: any) => {
              item.defaultValue = e.target.value;
              onTagDefinitionChanged(item, index);
            }}
          />
        &nbsp;
          <LegacyForms.Switch
            label="Read-only"
            onChange={(e: any) => {
              item.readonly = e.target.checked;
              onTagDefinitionChanged(item, index);
            }}
            checked={item.readonly}
          ></LegacyForms.Switch>
        &nbsp;
          <LegacyForms.Switch
            label="Hidden"
            onChange={(e: any) => {
              item.hidden = e.target.checked;
              onTagDefinitionChanged(item, index);
            }}
            checked={item.hidden}
          ></LegacyForms.Switch>
        </div>
      ))}
      <br />
      <Button onClick={addTagDefinition}>Add</Button>
    </div>
  );
};
