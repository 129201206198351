import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/data';
import { LegacyForms, Button } from '@grafana/ui';
import { TagInputOptions, TagData, TagDefinition } from 'types';
import { getDataSourceSrv as getDataSourceService, loadPluginCss } from '@grafana/runtime';
import axios from 'axios';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import moment from 'moment';

interface Props extends PanelProps<TagInputOptions> { }

export class TagInputPanel extends PureComponent<Props> {
  cssLoaded = false;
  skipLoad = false;
  isLoading = false;
  submitting = false;
  newTagValues: any = {};
  newFieldValues: any = {};
  newTimestamp: string | Moment = moment(new Date());
  errorText: string | undefined;
  state = {
    proxyUrl: '',
    cssLoaded: false,
  };

  proxyUrl: string | undefined;
  tags: TagData[] | undefined;
  fields: TagData[] | undefined;

  render() {
    if (!this.cssLoaded) {
      this.cssLoaded = true;
      loadPluginCss({
        dark: 'plugins/tag-input/css/base.css',
        light: 'plugins/tag-input/css/base.css',
      }).then(() => this.setState({ cssLoaded: true }));
      return null;
    }

    this.getProxyURL();
    this.loadTagsData();

    if (this.errorText) {
      return this.showError(this.errorText);
    } else {
      return this.renderTags();
    }
  }

  renderTags() {
    this.skipLoad = false;

    if (!this.tags || this.tags.length === 0 || !this.fields || this.fields.length === 0) {
      if (this.isLoading) {
        return (
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        );
      }
      return this.showError('no tags!');
    }

    const inputFields: React.ReactNode[] = [];
    const { options } = this.props;
    this.newTagValues = {};
    this.newFieldValues = {};

    if (options.editTimeStamp) {
      this.newTimestamp = moment(new Date());
      inputFields.push(
        <div className="ti-input">
          <div className="form-field">
            <label className="gf-form-label width-6">Time</label>
            <Datetime
              open={false}
              value={this.newTimestamp}
              dateFormat="DD/MM/YYYY"
              timeFormat="HH:mm"
              onChange={(value: string | Moment) => this.timestampChange(value)}
            />
          </div>
        </div>
      );
    }

    this.tags.forEach(tag => {
      if (tag.definition) {
        const def = tag.definition as TagDefinition;
        if (!def.hidden) {
          inputFields.push(
            <div className="ti-input ti-tag">
              <LegacyForms.FormField
                width={250}
                label={def.displayName ? def.displayName : def.name}
                readOnly={def.readonly || !!def.defaultValue}
                type="text"
                hidden={def.hidden}
                value={def.defaultValue ? this.checkForVariable(def.defaultValue) : undefined}
                placeholder={options.showLastValue ? tag.lastValue : undefined}
                onChange={(t: any) => this.onInputChange('tag', tag.name, t.target.value)}
              />
            </div>
          );
        }
      } else {
        inputFields.push(
          <div className="ti-input ti-tag">
            <LegacyForms.FormField
              label={tag.name}
              type="text"
              placeholder={options.showLastValue ? tag.lastValue : undefined}
              onChange={(t: any) => this.onInputChange('tag', tag.name, t.target.value)}
            />
          </div>
        );
      }
    });

    this.fields.forEach(field => {
      if (field.definition) {
        const def = field.definition as TagDefinition;
        if (!def.hidden) {
          inputFields.push(
            <div className="ti-input ti-field">
              <LegacyForms.FormField
                width={250}
                label={def.displayName ? def.displayName : def.name}
                readOnly={def.readonly || !!def.defaultValue}
                type="text"
                hidden={def.hidden}
                value={def.defaultValue ? this.checkForVariable(def.defaultValue) : undefined}
                placeholder={options.showLastValue ? field.lastValue : undefined}
                onChange={(t: any) => this.onInputChange('field', field.name, t.target.value)}
              />
            </div>
          );
        }
      } else {
        inputFields.push(
          <div className="ti-input ti-field">
            <LegacyForms.FormField
              label={field.name}
              type="text"
              placeholder={options.showLastValue ? field.lastValue : undefined}
              onChange={(t: any) => this.onInputChange('field', field.name, t.target.value)}
            />
          </div>
        );
      }
    });

    return (
      <div className="tag-input-container">
        {inputFields}
        <br />
        <div className="ti-buttons">
          <Button onClick={this.submitTags}>Submit</Button>
        </div>
      </div>
    );
  }

  onInputChange(type: string, name: string, value: any): void {
    if (type === 'tag') {
      this.newTagValues[name] = value;
    } else if (type === 'field') {
      this.newFieldValues[name] = value;
    }
  }

  timestampChange(value: string | Moment) {
    this.newTimestamp = value;
  }

  loadTagsData() {
    if (this.skipLoad || this.isLoading) {
      return;
    }
    if (!this.proxyUrl) {
      this.errorText = 'Proxy URL not found!';
      return;
    }
    if (!this.props.options.databaseKey) {
      this.errorText = 'Time Series DB database key not found!';
      return;
    }
    console.log('Load tags');
    this.tags = [];
    this.errorText = undefined;
    
    const dict: any = {};

    this.props.options.tagDefinitions.forEach(d => {
      if (d.defaultValue && d.name) {
        dict[d.name] = this.checkForVariable(d.defaultValue);
      }
    });

    const data = {
      DatabaseKey: this.checkForVariable(this.props.options.databaseKey),
      Tags: dict,
    };
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/input/tags/';
    this.isLoading = true;

    axios
      .post(URL, data)
      .then(response => {
        this.skipLoad = true;
        this.isLoading = false;
          console.log(response);
          console.log('response.data: ', response.data);
        this.buildTagsData(response.data);
      })
      .catch(e => {
        this.errorText = this.handleException(e);
        this.skipLoad = true;
        this.isLoading = false;
        this.setState({ tags: undefined, lastRequest: new Date() });
      });
  }

  buildTagsData(data: any) {
    const tagData: TagData[] = [];
    const fieldData: TagData[] = [];

    if(data){
      Object.keys(data).forEach(key => {
        if(key.toLowerCase() === 'tags'){
          Object.keys(data[key]).forEach(tagsKey => {
            tagData.push({
              name: tagsKey,
              lastValue: data[key][tagsKey],
              newValue: undefined,
              definition: this.props.options.tagDefinitions.find(d => d.name.toLowerCase() === tagsKey.toLowerCase()),
              type: undefined,
            });
          });
        } else if(key.toLowerCase() === 'fields'){
          Object.keys(data[key]).forEach(fieldsKey => {

            let fieldObject = data[key][fieldsKey];
            var fieldType = '';
            var lastValue = '';
            if(fieldObject.hasOwnProperty('value')){
              fieldType = data[key][fieldsKey].value;
              lastValue = data[key][fieldsKey].key;
            }else if(fieldObject.hasOwnProperty('Value')){
              fieldType = data[key][fieldsKey].Value;
              lastValue = data[key][fieldsKey].Key;
            }

            fieldData.push({
              name: fieldsKey,
              lastValue: lastValue,
              newValue: undefined,
              definition: this.props.options.tagDefinitions.find(d => d.name.toLowerCase() === fieldsKey.toLowerCase()),
              type: fieldType,
            });
          });
        }
      });
    }
    
    this.tags = tagData;
    this.fields = fieldData;
    this.setState({ tags: tagData, fields: fieldData });
  }

  getProxyURL() {
    const { publicApiName } = this.props.options;
    if (this.proxyUrl !== undefined) {
      return;
    }
    const srv = getDataSourceService();
    srv.get(publicApiName).then(result => {
      const us = result as any;
      this.proxyUrl = `/api/datasources/${us.id}/resources`;
      this.setState({ proxyUrl: this.proxyUrl });
    });
  }

  showError(text: string) {
    this.skipLoad = false;
    return (
      <div className="tag-input-error">
        <div>{text}</div>
      </div>
    );
  }

  submitTags = () => {
    if (!this.tags || this.tags.length === 0 || !this.fields || this.fields.length === 0) {
      alert('No tags found!');
      return;
    }
    if (this.submitting) {
      console.log('Submitting is already in progress...');
      return;
    }
    if (typeof this.newTimestamp === 'string') {
      alert('Invalid date!');
      return;
    }

    const tagsDict: any = {};
    this.tags.forEach(tag => {
      const val = this.getInputValue('tag', tag);
      if (val !== undefined) {
        tagsDict[tag.name] = val;
      }
    });
    const fieldsDict: any = {};
    this.fields.forEach(field => {
      const val = this.getInputValue('field', field);
      if (val !== undefined) {
        fieldsDict[field.name] = { key: val, value: field.type };
      }
    });

    if (this.isEmpty(tagsDict)) {
      alert('All tags are empty!');
      return;
    }
    if (this.isEmpty(fieldsDict)) {
      alert('All fields are empty!');
      return;
    }

    this.submitting = true;
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/input/submit/';
    const data = {
      DatabaseKey: this.checkForVariable(this.props.options.databaseKey),
      Tags: tagsDict,
      Fields: fieldsDict,
      Timestamp: this.props.options.editTimeStamp ? this.newTimestamp : undefined,
    };
    console.log('Submit data:');
    console.log(data);
    axios
      .post(URL, data)
      .then(response => {
        this.submitting = false;
        console.log(response);
        this.setState({ lastRequest: new Date() });
      })
      .catch(e => {
        this.errorText = this.handleException(e);
        alert(this.errorText);
        this.submitting = false;
        this.setState({ lastRequest: new Date() });
      });
  };

  getInputValue(type: string, input: TagData): any {
    if (input.definition) {
      const def = input.definition as TagDefinition;
      if (def.defaultValue) {
        return this.checkForVariable(def.defaultValue);
      }
    }

    if (type === 'tag') {
      return this.newTagValues[input.name];
    } else if (type === 'field') {
      return this.newFieldValues[input.name];
    }
  }

  checkForVariable(text: string, level: number = 1): string {
    const varText = this.props.replaceVariables(text);
    if (varText == text || level > 100)
    {
      return varText;
    }
    else
    {
      return this.checkForVariable(varText, level + 1);
    }
  }

  handleException(e: any): string {
    console.log(e);
    if (e.response.data) {
      if (e.response.data.Message) {
        return e.response.data.Message;
      }

      if (e.response.data.message) {
        return e.response.data.message;
      }
    }
    return e.toString();
  }

  isEmpty(map: any) {
    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
