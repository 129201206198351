export interface TagInputOptions {
  publicApiName: string;
  timeSeriesDB: string;
  timeSeriesMeasurement: string;
  databaseKey: string;
  editTimeStamp: boolean;
  showLastValue: boolean;
  tagDefinitions: TagDefinition[];
}

export const defaults: TagInputOptions = {
  publicApiName: 'ICB API',
  timeSeriesDB: '',
  timeSeriesMeasurement: '',
  databaseKey:'',
  editTimeStamp: false,
  showLastValue: true,
  tagDefinitions: [],
};

export type TagDefinition = {
  name: string;
  displayName: string;
  defaultValue: string;
  hidden: boolean;
  readonly: boolean;
};

export type TagData = {
  name: string;
  lastValue: string;
  newValue: any;
  definition: any;
  type: string | undefined;
};
