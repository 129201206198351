import { PanelPlugin } from '@grafana/data';
import { TagInputOptions, defaults } from './types';
import { TagInputPanel } from './TagInputPanel';
import { TagDefinitionsSetter } from 'TagDefinitionsSetter';

export const plugin = new PanelPlugin<TagInputOptions>(TagInputPanel).setPanelOptions(builder => {

    return builder
        .addTextInput({
            name: 'Public API name',
            path: 'publicApiName',
            category: ['Public API'],
            defaultValue: defaults.publicApiName
        }).addTextInput({
            name: 'Database Key',
            path: 'databaseKey',
            category: ['Time Series Settings'],
            defaultValue: defaults.timeSeriesMeasurement
        }).addBooleanSwitch({
            name: 'Edit timestamp',
            path: 'editTimeStamp',
            category: ['View options']
        }).addBooleanSwitch({
            name: 'Show last value',
            path: 'showLastValue',
            category: ['View options']
        }).addCustomEditor({
            name: 'Tag definitions',
            path: 'tagDefinitions',
            editor: TagDefinitionsSetter,
            id: '1',
            category: ['Tag definitions']
        });
});
